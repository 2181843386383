<template>
  <section class="ChooseHouse container" id="premie">
    <h2 class="ChooseHouse__title">VELG DIN FAVORITT OG BYGG I VEI!</h2>
    <p class="ChooseHouse__paragraph">Klarer du å bygge ferdig uten å spise av huset?</p>
    <div class="ChooseHouse__content container">
      <div
        v-for="(item, index) in houses"
        :key="index"
        class="ChooseHouse__item"
        :class="index % 2 == 0 ? 'ChooseHouse__item--even' : 'ChooseHouse__item--odd'"
      >
        <div class="ChooseHouse__item-image"><img :src="item.img" /></div>
        <div
          class="ChooseHouse__item-content"
          :class="
            index % 2 == 0
              ? 'ChooseHouse__item-content--even'
              : 'ChooseHouse__item-content--odd'
          "
        >
          <div class="ChooseHouse__item-content__text" v-html="item.content"></div>
          <ButtonComponent
            :label="'INSTRUKSJONER'"
            tag="button"
            @click="redirectExternal(index + 1)"
            theme="primary"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import hus1 from "@/assets/Husene/Hus_main.png";
  import hus2 from "@/assets/Husene/Hus_1.png";
  import hus3 from "@/assets/Husene/Hus_2.png";
  import hus4 from "@/assets/Husene/Hus_3.png";
  import hus5 from "@/assets/Husene/Fabrikk.png";

  export default {
    data() {
      return {
        houses: [
          {
            img: hus1,
            content:
              "<h3>JULEHYTTA</h3><p>Hva med å bygge en tradisjonell julehytte for å komme i skikkelig julestemning?<br/> <br/>Vanskelighetsgrad: 4 / 5 <br/> Produkter: 3</p>",
          },
          {
            img: hus2,
            content:
              "<h3>STABBURET</h3><p>Et lite eventyr av et hus. <br/>Tar du utfordringen?<br/> <br/>Vanskelighetsgrad: 4 / 5 <br/> Produkter: 3</p>",
          },
          {
            img: hus3,
            content:
              "<h3>JULELAVVO</h3><p>Sett opp lavvoen og hold varmen i julen med dine nærmeste.<br/> <br/>Vanskelighetsgrad: 1 / 5 <br/> Produkter: 3</p>",
          },
          {
            img: hus4,
            content:
              "<h3>ROSA LYKKE</h3><p>Et hus til å spise opp! Pynt huset, spis litt Non Stop, pynt videre, spis litt til. Det er tross alt jul.<br/> <br/>Vanskelighetsgrad: 2 / 5 <br/> Produkter: 3</p>",
          },
          {
            img: hus5,
            content:
              "<h3>FREIA-FABRIKKEN</h3><p>Klarer du å bygge den ikoniske Freia-Fabrikken? Vi utfordrer deg!<br/> <br/>Vanskelighetsgrad: 5 / 5 <br/> Produkter: 3</p>",
          },
        ],
      };
    },

    methods: {
      redirectExternal(index) {
        const externalURL = `hus/${index}`;
        window.location.href = externalURL;
      },
    },
  };
</script>

<style lang="scss">
  .ChooseHouse {
    background-color: $background-color;

    padding-block: 2rem;

    &__content {
      display: flex;
      flex-direction: column;

      @include respond-below("phone") {
        gap: 2rem;
      }

      @include respond-between("phone", "tablet") {
        gap: 5rem;
      }
    }

    &__title {
      margin-bottom: 0;
    }

    &__paragraph {
      margin-bottom: 2rem;
      margin-top: 0;
    }

    &__item {
      display: flex;
      flex-direction: column;

      &--odd {
        @include respond-above("phone") {
          flex-direction: row-reverse;
        }
      }
      &--even {
        @include respond-above("phone") {
          flex-direction: row;
        }
      }

      &-image {
        object-fit: cover;

        @include respond-above("phone") {
          width: 50%;
        }

        img {
          width: 100%;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 1rem;

        @include respond-above("phone") {
          align-items: start;
        }

        p,
        h3 {
          margin: 0;
          margin-bottom: 1em;
          text-align: left;
          max-width: 400px;
        }

        &--odd {
          @include respond-above("phone") {
            width: 35%;

            p,
            h3 {
              text-align: right;
              max-width: 400px;
            }

            align-items: end;
          }
        }

        .elder-button {
          margin-top: 1rem;
          width: 300px;

          @include respond-below("phone") {
            width: 90%;
          }
        }

        &__text {
          @include respond-below("phone") {
            padding: 0 1rem;

            h3 {
              text-align: center;
            }

            p {
              text-align: center;
            }
          }
        }
      }
    }

    /* &__button-wrapper {
      padding-inline: 2rem;
      display: flex;
      justify-content: space-around;
      gap: 1rem;

      .elder-button {
        width: 30%;
        font-size: 19px;
      }

      @include respond-below("phone") {
        .elder-button {
          white-space: unset;
          width: 50%;
        }
      }
    } */
  }
</style>
