<template>
  <section class="campaign-carusel">
    <div class="campaign-carusel-wrapper container">
      <h2>BYGG DITT EGET SJOKOLADEHUS</h2>
      <Carousel
        :per-page="1"
        :perPageCustom="[[992, 3]]"
        :scroll-per-page="false"
        :navigation-enabled="true"
        :pagination-enabled="false"
        :navigation-next-label="nextLabel"
        :navigation-prev-label="prevLabel"
        :mouse-drag="!isDesktop"
        :touch-drag="true"
        :loop="true"
        @page-change="onPageChange"
        :navigateTo="[navigateTo, true]"
      >
        <template v-if="isDesktop">
          <Slide
            v-for="(item, index) in houses"
            :key="index"
            :class="{ 'campaign-carusel__hero--active': index === currentSlide }"
            :data-index="index + 1"
          >
            <component
              :is="'div'"
              class="campaign-carusel__hero"
              @click="redirectExternal"
            >
              <img class="campaign-carusel__hero-image" :src="item.image" />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">{{ item.name }}</h3>
                </div>
                <div class="campaign-carusel__hero-content">
                  <h4 class="campaign-carusel__hero-subtitle">{{ item.subtitle }}</h4>
                </div>
              </div>
            </component>
          </Slide>
        </template>
        <template v-else>
          <slide data-index="1" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene-Beige/Hus_main_beige.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">JULEHYTTA</h3>
                </div>
                <div class="campaign-carusel__hero-content">
                  <h4 class="campaign-carusel__hero-subtitle">Vanskelighetsgrad 4 / 5</h4>
                </div>
              </div>
            </component>
          </slide>
          <slide data-index="2" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene-Beige/Hus_1_beige.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">STABBURET</h3>
                </div>
                <div class="campaign-carusel__hero-content">
                  <h4 class="campaign-carusel__hero-subtitle">Vanskelighetsgrad 4 / 5</h4>
                </div>
              </div>
            </component>
          </slide>
          <slide data-index="3" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene-Beige/Hus_2_beige.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">JULELAVVO</h3>
                </div>
                <div class="campaign-carusel__hero-content">
                  <h4 class="campaign-carusel__hero-subtitle">Vanskelighetsgrad 1 / 5</h4>
                </div>
              </div>
            </component></slide
          >
          <slide data-index="4" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene-Beige/Hus_3_beige.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">ROSA LYKKE</h3>
                </div>
                <div class="campaign-carusel__hero-content">
                  <h4 class="campaign-carusel__hero-subtitle">Vanskelighetsgrad 2 / 5</h4>
                </div>
              </div>
            </component></slide
          >
          <slide data-index="5" @slideclick="handleSlideClick">
            <component :is="'div'" class="campaign-carusel__hero">
              <img
                class="campaign-carusel__hero-image"
                src="/assets/Husene-Beige/Fabrikk_beige.png"
              />
              <div class="campaign-carusel__hero-content-wrapper">
                <div class="campaign-carusel__hero-content">
                  <h3 class="campaign-carusel__hero-name">FREIA-FABRIKKEN</h3>
                </div>
                <div class="campaign-carusel__hero-content">
                  <h4 class="campaign-carusel__hero-subtitle">
                    Vanskelighetsgrad 5 / 5"
                  </h4>
                </div>
              </div>
            </component></slide
          >
        </template>
      </Carousel>

      <div class="campaign-carusel__button-wrapper">
        <ButtonComponent
          :label="'BYGG SJOKOLADEHUS'"
          tag="button"
          @click="redirect(['BuildYourHouse'], { scrollTo: 0 })"
          theme="primary"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import { redirect as Redirect } from "@/utils";
  import { Slide, Carousel } from "vue-carousel";
  export default {
    props: {
      houses: Array,
      isCampaign: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        navigateTo: 0,

        currentSlide: 1,
        nextLabel:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>',
        prevLabel:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"/></svg>',
        windowWidth: window.innerWidth,
      };
    },
    computed: {
      isDesktop() {
        return this.windowWidth > 992 ? true : false;
      },
    },

    methods: {
      redirect(...target) {
        Redirect(...target, this)();
      },
      onPageChange(currentSlide) {
        if (isNaN(currentSlide)) return;
        let val = currentSlide + 1;

        this.currentSlide = val;

        this.navigateTo = currentSlide;

        if (this.isCampaign) return;

        let hero = this.houses[val].name;

        if (this.$route.query.helt === hero) return;

        this.$router.replace({
          path: this.$route.path,
          query: { helt: hero },
        });
      },
      redirectExternal() {
        const externalURL =
          this.currentSlide === 5 ? `hus/1` : `/hus/${this.currentSlide + 1}`;

        window.location.href = externalURL;
      },
      onResize() {
        this.windowWidth = window.innerWidth;
      },
      handleSlideClick(dataset) {
        console.log(dataset.index);

        window.location.href = dataset.index == 6 ? `/hus/1` : `/hus/${dataset.index}`;
      },
    },

    mounted() {
      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },

    components: {
      Slide,
      Carousel,
    },
  };
</script>

<style lang="scss">
  .campaign-carusel {
    background-image: url("@/assets/BG/BG_red_01.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 3rem 0;

    .container {
      padding-inline: 3rem;

      @include respond-below("phone") {
        padding-inline: 1.5rem;
      }
    }

    h2 {
      margin-bottom: 2rem;
      color: $beige;

      @include respond-below("phone") {
        margin: 0;
      }
    }
    p {
      color: $beige;

      @include respond-below("phone") {
        margin: 0;
      }
    }

    .VueCarousel {
      &-wrapper {
        padding-block: 3rem 6rem;

        @include respond-below("tablet") {
          padding-block: 0rem;
        }
      }

      &-navigation {
        &-prev {
          left: 50px !important;

          @include respond-above("phone") {
            transform: translateY(calc(-75%)) translateX(-100%);
          }

          @include respond-between("phone", "tablet") {
            left: 75px !important;
          }

          @include respond-below("phone") {
            left: 60px !important;
          }
        }
        &-next {
          right: 50px !important;

          @include respond-above("phone") {
            transform: translateY(calc(-75%)) translateX(100%);
          }

          @include respond-between("phone", "tablet") {
            right: 75px !important;
          }

          @include respond-below("phone") {
            right: 60px !important;
          }
        }
      }

      &-slide {
        transition: scale 0.3s ease-in;
        -webkit-transition: scale 0.3s ease-in;
      }

      &-navigation-button {
        padding: 0rem !important;
        outline: 0 !important;

        svg {
          border-radius: 100%;
          background: $green;

          padding: 0.5rem;

          $size: 50px;
          $responsiveSize: 50px;

          width: $size;
          height: $size;
          fill: $beige;

          @include respond-below("phone") {
            width: $responsiveSize;
            height: $responsiveSize;
            padding: 0.5rem;
          }
        }
      }
    }

    &__hero {
      color: $beige;

      position: relative;

      &--active {
        scale: 1.4;
        z-index: 2;

        @include respond-below("tablet") {
          scale: 1;
        }
      }

      &-content-wrapper {
        position: absolute;
        bottom: 0rem;
        width: 100%;

        @include respond-below("tablet") {
          bottom: 4rem;
        }

        @include respond-below("phone") {
          bottom: 1rem;
        }
      }

      &-content {
        display: flex;
        justify-content: center;

        h3 {
          color: $beige;
        }
      }

      &-image {
        object-fit: contain;
        object-position: center;

        border-radius: 100%;

        aspect-ratio: 1;
        width: 100%;
        height: 75%;

        //old style with filled out circles
        /* width: 80%; */
        /* background-color: $beige; */
        /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
      }
      &-name {
        transition: font-size 0.5s ease;
        -webkit-transition: font-size 0.5s ease;

        margin: 0;
        width: initial;
        background: $green;
        white-space: nowrap;
        padding: 0.25rem 0.5rem;
        font-size: 26px;
        transform: skew(-15deg);
        font-weight: 700;

        /* @include respond-below("phone") {
            font-size: 8px;
            padding: 0 0.5rem;
          } */
      }
      &-subtitle {
        transition: font-size 0.5s ease;
        -webkit-transition: font-size 0.5s ease;

        margin: 0;
        white-space: nowrap;
        background: $beige;
        font-family: "FS ME";
        padding: 0.4em 1em;
        font-size: 14px;
        width: fit-content;
        font-weight: 400;

        // skew gap
        margin-top: -1px;
        /*
          @include respond-below("phone") {
            font-size: 3px;
          } */
      }
    }

    &__button-wrapper {
      margin-top: 1rem;

      &-top {
        transform: translateY(-50%);
      }

      &-bottom {
        transform: translateY(50%);
      }

      display: flex;
      justify-content: center;
      gap: 15%;

      .elder-button {
        width: fit-content;
      }

      @include respond-below("phone") {
        justify-content: space-around;
        gap: 1rem;
        padding-inline: 1.5rem;

        .elder-button {
          white-space: unset;
          width: 100%;
        }
      }
    }
  }
</style>
