<template>
  <section class="section-a container">
    <h1>JULEHILSEN</h1>

    <div class="section-a__content">
      <img
        class="section-a__content__main-image"
        src="@/assets/Elements/Postcard_02.png"
      />
      <div class="section-a__content__subparent">
        <p>
          Julen er en magisk tid for både store og små. Det er tiden for å skape
          glede og minner, videreføre gamle og nye tradisjoner sammen med de du
          er glad i. I år ønsker vi dere alle en eventyrlig god jul, og håper at
          dere vil bygge noe magisk sammen!
        </p>

        <div class="section-a__content__subparent__image"></div>

        <img
          class="section-a__content__subparent__image"
          src="@/assets/Elements/Freia-logo-beige.svg"
        />
      </div>
    </div>

    <img
      class="section-a__content__subparent__image__mobile"
      src="@/assets/Elements/Freia-logo-beige.svg"
    />
  </section>
</template>
<script>
import { redirect as Redirect } from "@/utils";

export default {
  methods: {
    redirect(...target) {
      Redirect(...target, this)();
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-top: 1.5rem;
  margin-bottom: 0;
  color: #fff8e2;

  @include respond-below("phone") {
    margin: 0;
  }
}

.section-a {
  background-image: url("@/assets/BG/BG_red_03.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  max-width: none;

  padding-bottom: 3rem;
  padding-top: 3rem;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 0 1rem;

    &__main-image {
      width: 400px;

      @include respond-below("phone") {
        width: 300px;
        gap: 1rem;
      }
    }

    p {
      max-width: 500px;
      margin: 0;
      text-align: start;
      color: #fff8e2;
    }

    @include respond-below("phone") {
      gap: 0;
      flex-direction: column-reverse;
      margin-bottom: 0;

      p {
        text-align: center;
      }
    }

    &__subparent {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      &__image {
        width: 125px;
        align-self: flex-end;

        @include respond-below("phone") {
          margin-top: 1rem;
          align-self: center;
          display: none;
        }

        &__mobile {
          width: 75px;
          height: 75px;
          margin-bottom: 1rem;

          @include respond-above("phone") {
            display: none;
          }
        }
      }
    }
  }
}
</style>
