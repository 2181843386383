<template>
  <div class="campaign">
    <Cover :strength="150" />
    <SectionD/>
    <CampaignCarusel :houses="houses" v-if="houses" />
    <SectionB />
    <SectionA />
  </div>
</template>

<script>
  import Form from "@/components/Form.vue";
  import ChooseHouse from "@/components/ChooseHouse.vue";
  import CampaignCarusel from "@/components/CampaignCarusel.vue";
  import Cover from "@/components/Cover.vue";
  import SectionA from "@/components/SectionA.vue";
  import SectionB from "@/components/SectionB.vue";
  import SectionD from "@/components/SectionD.vue"

  import houses from "@/houses.json";

  export default {
    computed: {
      houses() {
        let data = houses;

        return [...data, data[0], data[1]];
      },
    },
    components: {
      Form,
      ChooseHouse,
      CampaignCarusel,
      Cover,
      SectionA,
      SectionB,
      SectionD
    },

    metaInfo() {
      return {
        title: "Kampanje | Freia",
      };
    },
  };
</script>

<style lang="scss">
  .campaign {
    text-align: center;
  }
</style>
