<template>
  <section class="section-d container">
    <div class="section-d__button-wrapper section-d__button-wrapper-top">
      <ButtonComponent
        :label="'BYGG SJOKOLADEHUS'"
        tag="button"
        @click="redirect(['BuildYourHouse'], { scrollTo: 0 })"
        theme="primary"
      />
      <ButtonComponent
        :label="'KONKURRANSE'"
        tag="button"
        @click="redirect(['Competition'], { scrollTo: 0 })"
        theme="primary"
      />
    </div>

    <h1>VI VIL GJERNE SE DITT SJOKOLADEHUS!</h1>

    <div class="section-d__content">
      <p>
        Inviter familie og venner, bygg sjokoladehus og del bilde/video i
        sosiale medier. Eller har du allerede vunnet verdens kuleste byggesett
        eller hatt lyst til å bygge den ikoniske Freia-fabrikken i sjokolade? Vi
        blir veldig glad om du tagger oss, så vi får se ditt mesterverk, på
        TikTok, Instagram eller Facebook.
      </p>

      <div class="section-d__socials">
        <a href="https://www.tiktok.com/@freia_norge">
          <img width="40px" src="@/assets/icons/tiktok_brown.svg" />
        </a>
        <a href="https://www.facebook.com/freia">
          <img width="45px" src="@/assets/icons/fb.svg" />
        </a>
        <a href="https://instagram.com/freia_norge?igshid=MzRlODBiNWFlZA==">
          <img width="40px" src="@/assets/icons/ig.svg" />
        </a>
      </div>
    </div>
  </section>
</template>
<script>
import { redirect as Redirect } from "@/utils";

export default {
  methods: {
    redirect(...target) {
      Redirect(...target, this)();
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  margin-top: 1.5rem;
  margin-bottom: 0;

  @include respond-below("phone") {
    margin: 0;
  }
}

.section-d {
  padding-bottom: 1rem;

  &__button-wrapper {
    &-top {
      transform: translateY(-50%);
    }

    display: flex;
    justify-content: center;
    gap: 10%;

    .elder-button {
      min-width: 25%;
    }

    @include respond-below("phone") {
      justify-content: space-around;
      gap: 1rem;
      padding-inline: 1.5rem;
      flex-direction: column;
      padding-top: 1.5rem;
      padding-bottom: 3rem;
      transform: translateY(0%);

      .elder-button {
        white-space: unset;
        min-width: 50%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;

    p {
      max-width: 900px;
      margin: 0;
      text-align: center;
      margin-top: 1rem;
    }

    @include respond-below("phone") {
      margin-bottom: 0;

      p {
        text-align: center;
      }
    }
  }

  &__socials {
    display: flex;
    gap: 2rem;
    justify-content: center;
    padding: 1rem;

    @include respond-above("phone") {
      justify-content: center;
    }
  }
}
</style>
