<template>
  <section class="section-b container">
    <h2>KONKURRANSE</h2>
    <div class="section-b__content">
      <img src="@/assets/Husene/Fabrikk.png" />
      <div class="section-b__content__subdiv">
        <p>
          Premien i årets store julekonkurranse er verdens kuleste byggesett, laget av
          verdens beste sjokolade. Nå kan du vinne byggesettet for den ikoniske
          Freia-fabrikken! Trykk på lenken, registrer deg og bli med i trekningen av årets
          mest smakfulle premie!
        </p>
        <div class="section-b__content__button-parent">
          <ButtonComponent
            :label="'DELTA I KONKURRANSEN'"
            tag="button"
            @click="redirect(['Competition'], { scrollTo: 0 })"
            theme="primary"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { redirect as Redirect } from "@/utils";

  export default {
    methods: {
      redirect(...target) {
        Redirect(...target, this)();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .section-b {
    padding-top: 3rem;

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin-bottom: 3rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      @include respond-below("phone") {
        gap: 2rem;
        flex-direction: column;
      }

      img {
        width: 400px;

        @include respond-below("phone") {
          width: 300px;

          gap: 2rem;
        }
      }

      p {
        max-width: 475px;
        margin: 0;
        text-align: start;
      }

      &__button-parent {
        margin-top: 2rem;
        text-align: left;

        @include respond-below("phone") {
          width: 100%;

          button {
            white-space: unset;
            min-width: 50%;
            width: 100%;
          }
        }
      }

      &__subdiv {
        @include respond-below("phone") {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            text-align: center;
          }
        }
      }
    }
  }
</style>
